<template>
  <div class="monthcard">
    <div class="plateNo">
      <div class="plateno-box">
        <div class="plate-title">
          <div class="icon">
            <img src="../../assets/monthcard-P.png" alt="" />
          </div>
          <div class="parkingname">{{ parklotName }}</div>
        </div>
        <div class="plate-box">
          <div class="input-plate-wrap">
            <div class="plate-for" v-for="(item, index) in keyBoard.kbLenght" :key="index" @click="getmenuItem(index)">
              <div
                class="plate-item"
                v-if="index != keyBoard.kbLenght - 1"
                :class="{ active: index === keyBoard.indexNum }"
                @click="carPlateInput(index)"
              >
                <i v-if="keyBoard.value[index] == ''" style="color: #a3a3a3; font-size: 18px"> - </i>
                {{ keyBoard.value[index] || "" }}
              </div>
              <!-- 新能源多一位车牌 -->
              <div
                class="plate-item-new"
                v-if="index == keyBoard.kbLenght - 1"
                :class="{ active: index === keyBoard.indexNum }"
                @click="carPlateInput(index)"
              >
                <i
                  v-if="keyBoard.value[keyBoard.kbLenght - 1] == '' && keyBoard.value.length == 8"
                  style="color: #00deb9; font-size: 12px; writing-mode: vertical-rl"
                >
                  新能源
                </i>
                {{ keyBoard.value[index] }}
              </div>
            </div>
          </div>
        </div>
        <van-button class="platebtn" round type="info" @click="queryplate" :loading="loading"> 查询 </van-button>
        <div class="history-plate" v-if="plateNoList.length">
          <div class="plate-num" v-for="(item, index) in plateNoList" :key="index">
            {{ item }}
          </div>
        </div>
      </div>
    </div>

    <div class="monthcard-status" v-if="monthMsgList != null">
      <div class="monthcard-status-box">
        <div class="message">
          <div class="msg-title">月卡状态</div>
          <div class="msg-text" style="color: #2370ff">
            {{ status == 0 ? "待生效" : status == 1 ? "已失效" : "生效中" }}
          </div>
        </div>
        <div class="message">
          <div class="msg-title">生效时间</div>
          <div class="msg-text" style="color: #333333">
            {{ monthMsgList.forceTimeStart }}
          </div>
        </div>
        <div class="message">
          <div class="msg-title">失效时间</div>
          <div class="msg-text" style="color: #333333">
            {{ monthMsgList.deadLineDate }}
          </div>
        </div>
      </div>
    </div>
    <!--  v-if="monthList.length" -->
    <div class="setmeal" v-if="monthList.length">
      <div class="examine-box" v-if="examineList.length">
        <div class="setmeal-title">
          套餐
          <span style="font-size: 15px; color: #a3a3a3"> ({{ plates }}) </span>
        </div>
        <div class="examine-card">
          <div
            class="setmeal-item"
            v-for="(item, index) in examineList"
            :key="item.id"
            :class="{ active: examineID === item.id }"
            @click="handleExamine(item)"
          >
            <div class="status" :style="{ color: item.auditPlate ? '#f4c004' : '#04c4f4' }">
              {{ item.auditPlate ? "待支付" : "待审核" }}
            </div>
            <div>{{ item.name }}</div>
            <div style="margin: 5px 0">
              {{ item.chargePeriod }}
              {{ item.chargeUnit == 0 ? "日" : item.chargeUnit == 1 ? "月" : "年" }}
            </div>
            <div>￥{{ item.fee }}</div>
          </div>
        </div>
        <div class="paybox">
          <div class="payment" v-if="auditPlate === 1">
            <van-button round type="info" @click="canclePay(1)"> 取消支付</van-button>
            <van-button round type="info" @click="monthpay"> 去支付{{ cash != null ? `￥${cash}` : "" }}</van-button>
          </div>
          <div class="unapprove" v-if="auditPlate === 0">
            <van-button round type="info" @click="canclePay(0)"> 取消审核</van-button>
          </div>
        </div>
      </div>
      <div class="setmeal-box" v-else>
        <div class="setmeal-title">
          {{ monthMsgList != null && status != 2 ? "续费" : "办理" }}套餐
          <span style="font-size: 15px; color: #a3a3a3"> ({{ plates }}) </span>
        </div>
        <div class="setmeal-card">
          <div
            class="setmeal-item"
            v-for="item in monthList"
            :key="item.id"
            :class="{ active: checkid === item.id }"
            @click="handleSetmeal(item)"
          >
            <div>{{ item.name }}</div>
            <div>
              {{ item.chargePeriod }}
              {{ item.chargeUnit == 0 ? "日" : item.chargeUnit == 1 ? "月" : "年" }}
            </div>
            <div>￥{{ item.fee }}</div>
          </div>
        </div>
        <div class="paybox">
          <van-button round type="info" @click="monthpay"> 去支付{{ cash != null ? `￥${cash}` : "" }} </van-button>
        </div>
      </div>
    </div>

    <!-- 车牌键盘 -->
    <div>
      <PlateInput
        :kbShow="keyBoard.isShow"
        :kbIndex="keyBoard.indexNum"
        :kbType="keyBoard.length"
        @childValue="keyboardValue"
        @childClose="keyboardClose"
        @childIndex="keyboardIndex"
        @childDel="keyboardDel"
      />
    </div>

    <van-popup v-model="mesgshow" :close-on-click-overlay="false" round class="usermsg">
      <div class="top-title">填写包月认证信息</div>
      <van-contact-edit :contact-info="editingContact" @save="onSave" />
    </van-popup>
  </div>
</template>

<script>
import { Dialog, Toast } from "vant";
import PlateInput from "./components/plateInput";

export default {
  name: "MonthCard",
  components: {
    PlateInput,
    components: {
      [Dialog.Component.name]: Dialog.Component,
    },
  },
  data() {
    return {
      loading: false,
      keyBoard: {
        //键盘
        isShow: false,
        kbLenght: 8, // 车牌输入框长度
        indexNum: " ", // 点击车牌设置焦点的下标
        value: ["", "", "", "", "", "", "", ""], // 键盘值
      },
      appId: localStorage.getItem("appId"),
      itemId: localStorage.getItem("itemId"),
      // 用户当前月卡信息
      monthMsgList: null,
      // 历史车牌
      plateNoList: [],
      // 月卡套餐
      monthList: [],
      // 月卡状态
      status: null,
      // 月卡套餐选中id
      checkid: 0,
      // 用户车牌
      plates: null,
      // 当前支付金额
      cash: null,
      // 停车场名称
      parklotName: null,
      // 审核套餐列表
      examineList: [],
      examineID: 0,
      // 待审核还是带支付状态
      auditPlate: null,
      // 审核对象
      examineOBJ: {},
      // 展示用户信息输入
      mesgshow: false,
      editingContact: {},
      domain: process.env.VUE_APP_TEST_DOMAIN,
    };
  },
  watch: {
    "keyBoard.value": {
      handler(val) {
        this.monthMsgList = null;
        this.monthList = [];
        this.plates = null;
        this.cash = null;
        this.checkid = 0;
        this.status = null;
        this.examineList = [];
        this.auditPlate = null;
        this.examineID = 0;
        this.examineOBJ = {};
      },
    },
  },
  mounted() {
    this.parklotName = localStorage.getItem("parkingName");
  },
  methods: {
    onSave(contactInfo) {
      let { name, tel } = contactInfo;
      let { checkid, plates, appId, itemId } = this;
      let openId = localStorage.getItem("openId");
      let alipayuserId = localStorage.getItem("alipayuserId");
      let payMethod = openId ? "wxpay" : "alipay";
      this.$axios
        .post(`${this.domain}/api/month/monthPrepay`, {
          appId,
          itemId,
          openId: openId ? openId : alipayuserId,
          plateNo: plates,
          standId: checkid,
          method: payMethod,
          name,
          phone: tel,
          isMonthAuth: "1",
        })
        .then((res) => {
          this.mesgshow = false;
          if (payMethod == "wxpay") {
            this.weixinPay(res.data.data.data);
          } else {
            this.tradePay(res.data.data.trade_no);
          }
        })
        .catch((e) => {
          this.$alert("当前包月未成功，请联系工作人员", "提示")
            .then(() => {
              return;
            })
            .catch(() => {
              return;
            });
        });
    },
    // 取消支付/取消审核
    canclePay(num) {
      let { examineOBJ } = this;
      Dialog.confirm({
        title: "提示",
        message: "是否确认取消?",
      })
        .then(() => {
          this.$axios
            .post(`${this.domain}/api/month/cancelAudit`, {
              id: examineOBJ.id,
              appId: examineOBJ.appId,
              itemId: examineOBJ.itemId,
              plateNo: this.plates,
              cancelAudit: num ? 1 : 0,
            })
            .then((res) => {
              this.queryplate();
              Toast.success("取消成功");
            })
            .catch((err) => {
              Toast.fail("取消失败");
            });
        })
        .catch(() => {});
    },
    // 审核或支付套餐选择
    handleExamine(item) {
      this.examineID = item.id;
      this.checkid = item.id;
      this.auditPlate = item.auditPlate;
      this.examineOBJ = item;
      this.cash = item.fee;
    },
    // 支付
    monthpay() {
      let { checkid, monthMsgList, plates, appId, itemId } = this;
      let openId = localStorage.getItem("openId");
      let alipayuserId = localStorage.getItem("alipayuserId");
      let payMethod = openId ? "wxpay" : "alipay";
      if (checkid != 0 && this.status != 3) {
        if (monthMsgList != null && this.status != 1) {
          this.$axios
            .post(`${this.domain}/api/month/monthRenewalPrepay`, {
              appId,
              itemId,
              openId: openId ? openId : alipayuserId,
              plates,
              comboId: checkid,
              payMethod,
            })
            .then((res) => {
              if (payMethod == "wxpay") {
                this.weixinPay(res.data.data.data);
              } else {
                this.tradePay(res.data.data.trade_no);
              }
            });
        } else {
          this.$axios
            .post(`${this.domain}/api/pay/getMonthAuthConfig`, {
              appId,
              itemId,
            })
            .then((res) => {
              if (res.data.data.value == "true") {
                this.mesgshow = true;
              } else {
                this.$axios
                  .post(`${this.domain}/api/month/monthPrepay`, {
                    appId,
                    itemId,
                    openId: openId ? openId : alipayuserId,
                    plateNo: plates,
                    standId: checkid,
                    method: payMethod,
                    name: null,
                    phone: null,
                    isMonthAuth: "0",
                  })
                  .then((res) => {
                    if (payMethod == "wxpay") {
                      this.weixinPay(res.data.data.data);
                    } else {
                      this.tradePay(res.data.data.trade_no);
                    }
                  })
                  .catch((e) => {
                    this.$alert("该车辆未认证，请联系工作人员", "提示")
                      .then(() => {
                        return;
                      })
                      .catch(() => {
                        return;
                      });
                  });
              }
            });
        }
      } else if (this.status == 3) {
        this.$alert("当前套餐已冻结，请联系工作人员处理", "提示")
          .then(() => {
            return;
          })
          .catch(() => {
            return;
          });
      } else {
        this.$alert("请先选择套餐", "提示")
          .then(() => {
            return;
          })
          .catch(() => {
            return;
          });
      }
    },
    // 查询用户是否已包月
    queryplate() {
      this.loading = true;
      let { keyBoard, appId, itemId } = this;
      let plateNo = "";
      let plateNoList = [];
      if (keyBoard.value[6] !== "") {
        plateNoList = keyBoard.value.filter((item) => {
          plateNo += item;
          return item !== "";
        });
        this.plates = plateNo;
      }
      if (plateNoList.length == 7 || plateNoList.length == 8) {
        this.$axios
          .post(`${this.domain}/api/month/getMonthCard`, {
            appId,
            itemId,
            plateNo,
          })
          .then((res) => {
            if (res.data.data.parkStandInfos !== null) {
              if (res.data.data.parkMonthInfo != null) {
                this.monthMsgList = res.data.data.parkMonthInfo;
                this.status = res.data.data.status;
              }
              if (res.data.data.auditStandInfos !== null) {
                this.examineList = res.data.data.auditStandInfos;
              } else {
                this.examineList = [];
              }
              this.monthList = res.data.data.parkStandInfos;
              this.loading = false;
            } else {
              this.loading = false;
              this.$alert("暂无月卡套餐", "提示")
                .then(() => {
                  return;
                })
                .catch(() => {
                  return;
                });
            }
          })
          .catch((e) => {
            this.loading = false;
          });
      } else {
        this.loading = false;
        this.$alert("请先填写车牌号", "提示")
          .then(() => {
            return;
          })
          .catch(() => {
            return;
          });
      }
    },
    // 套餐选择
    handleSetmeal(item) {
      this.checkid = item.id;
      this.cash = item.fee;
    },
    //点击车牌输入框
    carPlateInput(res) {
      this.keyBoard.isShow = true; //显示键盘
      this.keyBoard.indexNum = res; //设置键盘焦点下标
    },
    //键盘点击返回的值
    keyboardValue(res) {
      this.keyBoard.value.splice(this.keyBoard.indexNum, 1, res);
      // this.$setObject(this.keyBoard.value, this.keyBoard.indexNum, res);
    },
    //删除键盘值
    keyboardDel(res) {
      this.keyBoard.value.splice(res, 1, "");
      // this.$setObject(this.keyBoard.value, res, "");
    },
    //关闭
    keyboardClose(res) {
      this.keyBoard.isShow = res;
      this.keyBoard.indexNum = ""; //关闭键盘键盘高亮取消
    },
    //车牌焦点下标
    keyboardIndex(res) {
      this.keyBoard.indexNum = res;
    },
    // 车牌下标展示
    getmenuItem(index) {},
    // 微信收银台
    //解决微信内置对象报错
    weixinPay(data) {
      var that = this;
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener("WeixinJSBridgeReady", that.onBridgeReady(data), false);
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", that.onBridgeReady(data));
          document.attachEvent("onWeixinJSBridgeReady", that.onBridgeReady(data));
        }
      } else {
        that.onBridgeReady(data);
      }
    },
    // 微信内置浏览器类，weChatParameter对象中的参数是3.步骤代码中从后端获取的数据
    onBridgeReady(data) {
      WeixinJSBridge.invoke("getBrandWCPayRequest", data, (res) => {
        if (res.err_msg == "get_brand_wcpay_request:ok") {
          this.$alert("月卡购买成功", "提示")
            .then(() => {
              WeixinJSBridge.call("closeWindow");
            })
            .catch(() => {
              WeixinJSBridge.call("closeWindow");
            });
        } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
          alert("支付已取消，请重新扫码");
        } else {
          this.$router.push("/inpark/fail");
        }
      });
    },
    // 支付宝收银台
    // 由于js的载入是异步的，所以可以通过该方法，当AlipayJSBridgeReady事件发生后，再执行callback方法
    ready(callback) {
      if (window.AlipayJSBridge) {
        callback && callback();
      } else {
        document.addEventListener("AlipayJSBridgeReady", callback, false);
      }
    },
    tradePay(tradeNO) {
      let that = this;
      this.ready(function () {
        // 通过传入交易号唤起快捷调用方式(注意tradeNO大小写严格)
        AlipayJSBridge.call(
          "tradePay",
          {
            tradeNO: tradeNO,
          },
          function (data) {
            if (data.resultCode === "9000") {
              AlipayJSBridge.call("closeWebview");
            } else {
              alert("支付失败，请重新扫码");
            }
          }
        );
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.monthcard {
  min-height: 100vh;
  overflow-y: auto;
  background-color: rgb(248, 248, 248);

  .plateNo {
    padding: 15px 15px 5px;

    .plateno-box {
      padding: 20px 15px;
      box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
      border-radius: 5px;
      background-color: #ffffff;

      .plate-title {
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 18px;
          height: 18px;
          background-color: #2370ff;
          border-radius: 50%;

          img {
            width: 6px;
            height: 9px;
          }
        }

        .parkingname {
          margin-left: 5px;
          color: #333333;
          font-size: 15px;
        }
      }

      .plate-box {
        margin-bottom: 20px;
        position: relative;

        .input-plate-wrap {
          display: flex;
          justify-content: space-between;

          .plate-for {
            position: relative;

            .plate-item {
              box-sizing: border-box;
              position: relative;
              width: 34px;
              height: 50px;
              line-height: 50px;
              font-size: 18px;
              text-align: center;
              box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.08);
              border-radius: 5px;
              background-color: #ffffff;
            }

            .plate-item-new {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 36px;
              height: 50px;
              box-sizing: border-box;
              background-color: rgba(0, 222, 185, 0.1);
              border: 1px dashed #00bc9d;
              border-radius: 5px;
              font-size: 18px;
            }
          }

          .point {
            display: flex;
            width: 14px;
            height: 14px;
            border-radius: 14px;
            margin-top: 40px;
            background-color: #c3c2cb;
          }

          .icondiandongche {
            color: green;
            font-size: 20px;
          }
        }
      }

      .platebtn {
        display: block;
        margin-bottom: 20px;
        width: 100%;
        height: 40px;
        background-color: #2370ff;
      }

      .history-plate {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .plate-num {
          margin: 0 10px 5px 0;
          padding: 5px 15px;
          border: 1px solid rgb(229, 229, 229);
          font-size: 15px;
          color: #a3a3a3;
        }

        :last-child {
          margin-right: 0;
        }
      }
    }
  }

  .monthcard-status {
    padding: 10px 15px;

    .monthcard-status-box {
      padding: 15px;
      background-color: #ffffff;
      box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
      border-radius: 5px;

      .message {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 15px;
        color: #a3a3a3;
      }

      :last-child {
        margin-bottom: 0;
      }
    }
  }

  .setmeal {
    padding: 0 15px 10px;

    .setmeal-box {
      position: relative;
      padding: 20px 15px;
      background-color: #ffffff;
      box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
      border-radius: 5px;

      .setmeal-title {
        margin-bottom: 20px;
        color: #333333;
        font-size: 18px;
      }

      .setmeal-card {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .setmeal-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          margin-bottom: 10px;
          margin-left: 7px;
          height: 80px;
          width: 98px;
          border: 1px solid rgb(229, 229, 229);
          border-radius: 5px;
          color: #a3a3a3;
          font-size: 13px;
        }

        :first-child {
          margin-left: 0;
        }

        :nth-child(4) {
          margin-left: 0;
        }

        :nth-child(7) {
          margin-left: 0;
        }

        :nth-child(10) {
          margin-left: 0;
        }

        :nth-child(13) {
          margin-left: 0;
        }

        :nth-child(16) {
          margin-left: 0;
        }

        :nth-child(19) {
          margin-left: 0;
        }

        :nth-child(22) {
          margin-left: 0;
        }

        :nth-child(25) {
          margin-left: 0;
        }
      }

      .paybox {
        width: 100%;
        height: 44px;

        button {
          width: 100%;
          height: 100%;
          background-color: rgb(35, 112, 255);
        }
      }
    }

    .examine-box {
      position: relative;
      padding: 20px 15px;
      background-color: #ffffff;
      box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.05);
      border-radius: 5px;
      .setmeal-title {
        margin-bottom: 20px;
        color: #333333;
        font-size: 18px;
      }

      .examine-card {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .setmeal-item {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: end;
          padding-bottom: 5px;
          margin-bottom: 10px;
          margin-left: 7px;
          height: 85px;
          width: 98px;
          border: 1px solid rgb(229, 229, 229);
          border-radius: 5px;
          color: #a3a3a3;
          font-size: 13px;

          .status {
            position: absolute;
            right: 3px;
            top: 0;
          }
        }

        :first-child {
          margin-left: 0;
        }

        :nth-child(4) {
          margin-left: 0;
        }

        :nth-child(7) {
          margin-left: 0;
        }

        :nth-child(10) {
          margin-left: 0;
        }

        :nth-child(13) {
          margin-left: 0;
        }

        :nth-child(16) {
          margin-left: 0;
        }

        :nth-child(19) {
          margin-left: 0;
        }

        :nth-child(22) {
          margin-left: 0;
        }

        :nth-child(25) {
          margin-left: 0;
        }
      }

      .paybox {
        width: 100%;
        height: 44px;

        .unapprove {
          height: 100%;

          button {
            width: 100%;
            height: 100%;
            background-color: rgb(35, 112, 255);
          }
        }

        .payment {
          display: flex;
          justify-content: space-around;

          :nth-child(1) {
            flex-basis: 100px;
          }
          :nth-child(2) {
            flex-basis: 200px;
          }
        }
      }
    }
  }
}
.active {
  background-color: rgba(35, 112, 255, 0.1) !important;
  border: 1px solid rgb(35, 112, 255) !important;
  color: #2370ff !important;
}
.usermsg {
  width: 80%;
  padding: 20px;

  .top-title {
    text-align: center;
    font-size: 18px;
  }
}
</style>
